import React from "react"
import { DeductivePageLink } from "./link"
import styles from "./cta.module.css"

export interface CTAProps {
  title: string
  internalLink?: any
  externalLink?: string
}

export const PrimaryCTA = (data: CTAProps) => {
  const { title, internalLink, externalLink } = data
  return (
    <DeductivePageLink
      externalLink={externalLink}
      internalLink={internalLink}
      className={styles.primary}
    >
      {title}
    </DeductivePageLink>
  )
}

export const SecondaryCTA = (data: CTAProps) => {
  const { title, internalLink, externalLink } = data
  return (
    <DeductivePageLink
      externalLink={externalLink}
      internalLink={internalLink}
      className={styles.secondary}
    >
      {title}
    </DeductivePageLink>
  )
}

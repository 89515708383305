import React, { useState } from "react";
import cx from "classnames";

import { StandardText, StandardTextProps } from "./standardText";
import { Image } from "../image";
import ctaStyles from "../cta.module.css";

export interface StaffListModuleProps {
  data: {
    staff: {
      image: {};
      name: string;
      title: string;
      bio: any;
    }[];
  };
}

export const StaffListModule = ({ data }: StaffListModuleProps) => {
  const { staff } = data;
  const [openBio, setOpenBio] = useState(null);
  const textColorClasses = [
    "text-ocean",
    "text-marigold",
    "text-lapis",
    "text-firetruck",
    "text-pinkcrayon",
    "text-violet",
    "text-ocean",
  ];

  return (
    <div className="my-10 md:my-24 max-w-3xl mx-auto px-4">
      {staff.map(({ image, name, title, bio }, index) => (
        <div
          className={cx(
            "flex flex-col items-center justify-center bg-white pt-6 md:pt-0 mb-10 md:mb-44 ",
            {
              "md:flex-row": index % 2 === 0,
              "md:flex-row-reverse": index % 2 !== 0,
            }
          )}
          key={index}
        >
          <div className="md:-my-10">
            <Image
              imageId={image.asset._id || image.asset._ref}
              aspectRatio={0.75}
              className="w-72 h-full"
            />
          </div>
          <div className="max-w-sm mx-4 md:mx-8 py-8">
            <h1 className={textColorClasses[index % textColorClasses.length]}>
              {name}
            </h1>
            <h5 className="font-sans font-medium mb-3">{title}</h5>
            {openBio === index ? (
              <div>
                <StandardText data={bio as StandardTextProps["data"]} />
                <button
                  className={ctaStyles.link}
                  onClick={() => setOpenBio(null)}
                >
                  Read Less
                </button>
              </div>
            ) : (
              <div>
                <p>{bio.text[0].children[0].text}</p>
                <button
                  className={ctaStyles.link}
                  onClick={() => setOpenBio(index)}
                >
                  Read More
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import styles from "./standardText.module.css"

export interface StandardTextProps {
  data: {
    text: any[]
  }
}

export const StandardText = ({ data }: StandardTextProps) => {
  const { text } = data
  return (
    <div className={styles.st}>
      <BlockContent blocks={text} />
    </div>
  )
}

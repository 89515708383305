import React from "react";

export interface TableModuleProps {
  data: {
    title?: string;
    table: {
      rows: {
        cells: {
          value: string;
        }[];
      }[];
    };
  };
}

export const TableModule = ({ data }: TableModuleProps) => {
  const { title, table } = data;
  return (
    <div className="w-full max-w-4xl mx-auto mb-10">
      {title && <h4 className="text-center mb-3">{title}</h4>}
      <table className="mx-auto px-4 text-sm md:text-base leading-tight">
        <thead>
          <tr>
            {table.rows[0].cells.map((cell, i) => (
              <th key={i} className="px-4 py-2 font-medium text-ocean">
                {cell.value}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {table.rows.slice(1, table.rows.length).map((row, i) => (
            <tr key={i}>
              {row.cells.map((cell, i) => (
                <td className="border px-4 py-2 font-medium" key={i}>
                  {cell.value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

import React from "react";
import cx from "classnames";
import { SecondaryCTA, CTAProps } from "../cta";
import { Image } from "../image";

export interface FiftyFiftyModuleProps {
  data: {
    sections: Array<{
      headline: string;
      body: string;
      cta: CTAProps;
      image: any;
      color: {
        color: string;
      };
    }>;
  };
}

export const FiftyFiftyModule = ({ data }: FiftyFiftyModuleProps) => {
  const { sections } = data;
  const bgColorClasses = {
    ocean: "bg-ocean",
    marigold: "bg-marigold",
    lapis: "bg-lapis",
    firetruck: "bg-firetruck",
    pinkcrayon: "bg-pinkcrayon",
    violet: "bg-violet",
    default: "bg-ocean",
  };

  return (
    <div className="max-w-6xl mx-auto w-full my-10">
      {sections.map(({ headline, body, cta, image, color }, index) => (
        <div
          key={index}
          className={`flex flex-col mb-8 ${
            index === 0 || index % 2 === 0
              ? "md:flex-row"
              : "md:flex-row-reverse"
          }`}
        >
          <Image imageId={image.asset.id} className="md:flex-1" />
          <div className="md:flex-1 flex flex-col">
            <div
              className={`h-4 lg:h-8 mb-2 md:mx-6 ${
                bgColorClasses[color ? color.color : ""] ||
                bgColorClasses["default"]
              }`}
            />
            <div className="px-4 flex-1 md:mx-6 pt-4">
              <div>
                <h2 className="text-ocean mb-2 lg:mb-4">{headline}</h2>
                <p className="mb-6">{body}</p>
                {cta && <SecondaryCTA {...cta} />}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

import React from "react";
import { FileLink } from "../link";

export interface FileLinkModuleProps {
  data: {
    title: string;
    form: {
      asset: {
        url: string;
      };
    };
  };
}

export const FileLinkModule = ({ data }: FileLinkModuleProps) => {
  return (
    <div className="max-w-4xl mx-auto">
      <FileLink file={data.form} title={data.title} />
    </div>
  );
};

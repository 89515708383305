import React from "react";

import { StandardText, StandardTextProps } from "./global/standardText";
import { HeroModule, HeroModuleProps } from "./global/heroModule";
import {
  TestimonialsModule,
  TestimonialsModuleProps,
} from "./global/testimonialsModule";
import {
  FiftyFiftyModule,
  FiftyFiftyModuleProps,
} from "./global/fiftyFiftyModule";
import { ImageModule, ImageModuleProps } from "./global/imageModule";
import {
  ImageGalleryModule,
  ImageGalleryModuleProps,
} from "./global/imageGalleryModule";
import { TableModule, TableModuleProps } from "./global/tableModule";
import {
  StaffListModule,
  StaffListModuleProps,
} from "./global/staffListModule";
import { FileLinkModule, FileLinkModuleProps } from "./global/fileLinkModule";

export const Modules = ({
  reactModule,
  type,
}: {
  type: string;
  reactModule: any;
}) => {
  switch (type) {
    case "standardText":
      return (
        <div className="max-w-4xl mx-auto px-4 w-full py-8">
          <StandardText data={reactModule as StandardTextProps["data"]} />
        </div>
      );
    case "heroModule":
      return <HeroModule data={reactModule as HeroModuleProps["data"]} />;
    case "testimonialsModule":
      return (
        <TestimonialsModule
          data={reactModule as TestimonialsModuleProps["data"]}
        />
      );
    case "fiftyFiftyModule":
      return (
        <FiftyFiftyModule data={reactModule as FiftyFiftyModuleProps["data"]} />
      );
    case "imageModule":
      return <ImageModule data={reactModule as ImageModuleProps["data"]} />;
    case "imageGallery":
      return (
        <ImageGalleryModule
          data={reactModule as ImageGalleryModuleProps["data"]}
        />
      );
    case "tableModule":
      return <TableModule data={reactModule as TableModuleProps["data"]} />;
    case "staffList":
      return (
        <StaffListModule data={reactModule as StaffListModuleProps["data"]} />
      );
    case "fileLink":
      return (
        <FileLinkModule data={reactModule as FileLinkModuleProps["data"]} />
      );
    default:
      return <span>{type}</span>;
  }
};

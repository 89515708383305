import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import cx from "classnames";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export interface TestimonialsModuleProps {
  data: {
    headline: string;
    testimonials: Array<{
      testimonial: string;
      author: string;
      authorType: string;
    }>;
  };
}

export const TestimonialsModule = ({ data }: TestimonialsModuleProps) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const { headline, testimonials } = data;
  const slider = useRef();
  useEffect(() => {
    slider.current.slickGoTo(activeSlide);
  }, [activeSlide]);

  return (
    <div className="bg-white text-center pt-10 pb-16">
      <h5 className="font-sans text-ocean pb-10">{headline}</h5>
      <Slider
        autoplay={true}
        autoplaySpeed={5000}
        ref={(s) => (slider.current = s)}
        beforeChange={(_, index) => setActiveSlide(index)}
      >
        {testimonials.map(({ testimonial, author, authorType }) => (
          <div className="mb-6">
            <p className="font-serif max-w-2xl mx-auto text-lg text-pinkcrayon">
              “{testimonial}”
            </p>
            <p className="text-pinkcrayon">
              — {author}, {authorType}
            </p>
          </div>
        ))}
      </Slider>
      <ul className="flex justify-center items-center">
        {testimonials.map((_, i) => (
          <li
            onClick={() => setActiveSlide(i)}
            className={cx(
              "border border-pinkcrayon rounded-full mx-2 cursor-pointer hover:bg-pinkcrayon transition-all duration-150",
              {
                "w-1.5 h-1.5": activeSlide !== i,
                "bg-pinkcrayon w-2 h-2": activeSlide === i,
              }
            )}
          />
        ))}
      </ul>
    </div>
  );
};

import React from "react";

import { BackgroundImage } from "../image";
import { PrimaryCTA, CTAProps } from "../cta";

export interface HeroModuleProps {
  data: {
    image: any;
    headline: string;
    cta: CTAProps;
  };
}

export const HeroModule = ({ data }: HeroModuleProps) => {
  const { image, headline, cta } = data;
  return (
    <div className="">
      <BackgroundImage
        imageId={image.asset.id}
        className={"w-full"}
        bgColor={image.asset.metadata.palette.muted.background}
      >
        <div className="max-w-2xl md:max-w-4xl mx-auto px-2 py-24 md:py-36 text-center ">
          <h1 className="text-white leading-snug mb-6 text-xl sm:text-3xl">
            {headline}
          </h1>
          {cta && <PrimaryCTA {...cta} />}
        </div>
      </BackgroundImage>
    </div>
  );
};

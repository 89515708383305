import React from "react";
import cx from "classnames";
import { Image } from "../image";

export interface ImageModuleProps {
  data: {
    image: {
      asset: {
        _id?: string;
        _ref?: string;
        url: string;
      };
    };
    caption?: string;
    layout: string;
  };
}

export const ImageModule = ({ data }: ImageModuleProps) => {
  const { image, caption, layout } = data;
  return (
    <div
      className={cx("mb-4", {
        "w-full": layout === "full",
        "max-w-6xl mx-auto px-4": layout === "large",
        "max-w-4xl mx-auto px-4": layout === "medium",
      })}
    >
      <Image imageId={image.asset._id || image.asset._ref} />
      {caption && <figcaption className="italic">{caption}</figcaption>}
    </div>
  );
};

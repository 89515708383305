import React from "react";
import { ImageGallery } from "../imageGallery";

export interface ImageGalleryModuleProps {
  data: {
    images: any[];
    title?: string;
    showTitle?: boolean;
  };
}

export const ImageGalleryModule = ({ data }: StandardTextProps) => {
  const { images, showTitle, title } = data;
  return (
    <div className="px-4 max-w-6xl mx-auto py-8">
      {showTitle && title && (
        <h3 className="text-center mb-8 text-ocean">{title}</h3>
      )}
      <ImageGallery images={images} />
    </div>
  );
};
